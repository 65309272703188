.center-text {
    text-align: center;
}

.color-white {
    color: white;
}

.color-info {
    color: $info !important;
}

.color-primary {
    color: $primary !important;
}

.data-table-container ul {
    justify-content: flex-end !important;
}

.btn-add {
    width: 120px;
}

.max-width-400 {
    max-width: 400px;
}

.link-black {
    color: rgba(0,0,0,0.87);
}

.link-action {
    color: #3c4b64;
}

.force-right {
    float: right;
}

#file-input:hover {
    cursor: pointer;
}

.action-icon {
    color: $primary;
}

.action-icon:hover {
    cursor: pointer;
}

// Login
#sign-up-container {
    width: 44%;
    border: 2px solid $primary;
}

#btn-sign-up {
    background-color: white !important;
    color: $primary;
    border: 1px solid $primary;
}

#btn-sign-up:hover {
    background-color: $primary !important;
    color: white;
    border: 1px solid $primary;
}

// Sign up
.logo-container img {
    width: 200px;
}

.branding-border {
    border: 1px solid $primary;
}

.header-text {
    color: $primary;
    font-size: 1.3em;
    font-weight: 500;
}

.subheader-text {
    color: $primary;
    font-size: 1.2em;
    font-weight: 400;
}

.label-value {
    font-size: 1.1em;
    font-weight: 500;
}

.order-label {
    font-weight: 300;
}

// Toast
.toaster-header-success {
    background-color: $light-green;
    opacity: 0.8;
    color: black;
}

.toaster-body-success {
    background-color: $light-green;
}

// Dropdown-Select
.valid-field {
    border: 1px solid #2eb85c !important;
}

.invalid-field {
    border: 1px solid red !important;
}

#new-order-status {
    width: 200px !important;
}

// App Settings
.app_banner {
    height: 400px;
}