// Variable overrides
$bg-primary: #ff6f61;
$primary: #ff6f61;
$bg-danger: #dc3545;
$danger: #dc3545;
$bg-info: #028fff;
$info: #028fff;

$white: #FFFFFF;

$light-green: #90EE90;